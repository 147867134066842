:root {
  --PrimaryColor: goldenrod;
  --SecondaryColor: rgb(152, 113, 14);
  --textColor: antiquewhite;
  --iconColor: antiquewhite;
  --gradientColor: linear-gradient(to right, yellow, rgb(255, 195, 42));
  --backgroundColor: rgba(0, 0, 0, 0.2);
  --whiteColor: white;
  --blackColor: black;
}

.tour {
  width: 90%;
  background: var(--backgroundColor);
  margin: 0 auto;
  padding-top: 20vh;
}
.tour .tourTitle {
  font-family: Garamond, Helvetica, serif;
  font-size: 2.1rem;
  color: var(--PrimaryColor);
  text-align: center;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.tour .secContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2rem;
}
.tour .secContent .newsCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  max-height: auto;
  overflow: hidden;
  border: 1px solid rgb(24, 20, 12);
  border-radius: 4px;
  background: var(--backgroundColor);
  margin: 5px auto;
  transition: 0.5s;
}
.tour .secContent .newsCard:hover {
  transition: 1s;
  transform: scale(1.025);
  box-shadow: 0 0 2px 2px rgb(19, 19, 19);
  border: none;
}
.tour .secContent .newsCard .cardInfo {
  padding: 20px;
  line-height: 1.5;
}
.tour .secContent .newsCard .cardInfo .cardTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tour .secContent .newsCard .cardInfo .cardTitle .title {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.8rem;
  color: var(--PrimaryColor);
  padding-bottom: 15px;
  padding-right: 50px;
  text-align: center;
}
.tour .secContent .newsCard .cardInfo .cardTitle .date {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.8rem;
  color: var(--PrimaryColor);
  padding-bottom: 15px;
  right: 5rem;
}
.tour .secContent .newsCard .cardInfo .location {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.6rem;
  color: var(--PrimaryColor);
  transition: 0.3s;
}
.tour .secContent .newsCard .cardInfo .location:hover {
  color: rgb(255, 230, 167);
  transition: 0.3s;
}
.tour .secContent .newsCard .cardInfo .text {
  font-family: Garamond, Helvetica, serif;
  font-size: 1rem;
  color: rgb(182, 136, 22);
  padding: 0 20px 0 20px;
}

@media screen and (max-width: 959px) {
  .tour {
    width: 100%;
  }
  .tour .secContent .newsCard {
    width: 80%;
  }
}
@media screen and (max-width: 500px) {
  .tour {
    width: 100%;
  }
  .tour .tourTitle {
    font-size: 1.9rem;
    text-align: center;
    margin: 0 auto;
    padding: 1rem 0 2rem 0;
  }
  .tour .secContent .newsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 1.2rem 0 1.2rem 0;
  }
  .tour .secContent .newsCard .cardInfo {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .tour .secContent .newsCard .cardInfo .cardTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tour .secContent .newsCard .cardInfo .cardTitle .title, .tour .secContent .newsCard .cardInfo .cardTitle .date {
    display: flex;
    font-size: 1.5rem;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;

  }
  .tour .secContent .newsCard .cardInfo .location, .tour .secContent .newsCard .cardInfo .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.35rem;
    padding: 0;
  }
}/*# sourceMappingURL=tour.css.map */