.about {
  width: 90%;
  background: var(--backgroundColor);
  margin: auto;
  padding-top: 20vh;
}
.about .aboutTitle {
  font-family: Garamond, Helvetica, serif;
  font-size: 2.2rem;
  color: var(--PrimaryColor);
  text-align: center;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 40px;
}
.about .presseText1 {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.2rem;
  font-weight: 550;
  color: rgb(218, 195, 142);
  text-align: center;
  line-height: 1.75;
  max-width: 80%;
  margin: 0 auto;
  padding-bottom: 45px;
}
.about .presseText2 {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.2rem;
  color: rgb(218, 195, 142);
  text-align: center;
  line-height: 1.75;
  max-width: 80%;
  margin: 0 auto;
  padding-bottom: 45px;
}
.about .secContent {
  justify-content: center;
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 50px;
}
.about .secContent .aboutCard {
  display: flex;
  flex-direction: column;
  justify-content: left;
  min-width: 350px;
  max-height: auto;
  overflow: hidden;
  border: 1px solid rgb(24, 20, 12);
  border-radius: 4px;
  background: var(--backgroundColor);
  padding-bottom: 25px;
  margin: 15px auto;
  transition: 0.5s;
}
.about .secContent .aboutCard:hover {
  transition: 1s;
  transform: scale(1.05);
  box-shadow: 0 0 5px 5px var(--SecondaryColor);
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
}
.about .secContent .aboutCard img {
  width: 350px;
  height: 350px;
  padding-bottom: 1vh;
}
.about .secContent .aboutCard .cardInfo {
  padding: 20px;
  line-height: 1.5;
}
.about .secContent .aboutCard .name {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.75rem;
  color: var(--PrimaryColor);
  padding-bottom: 15px;
  text-align: center;
}
.about .secContent .aboutCard .linkName {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.1rem;
  color: var(--PrimaryColor);
  padding-left: 5px;
  transition: 0.3s;
}
.about .secContent .aboutCard .linkName:hover {
  color: rgb(255, 230, 167);
  transition: 0.3s;
}
.about .secContent .aboutCard .instrument, .about .secContent .aboutCard .skill, .about .secContent .aboutCard .link {
  font-family: Garamond, Helvetica, serif;
  font-size: 1rem;
  color: rgb(182, 136, 22);
  padding: 0 20px 0 20px;
}
.about .modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2000;
}
.about .modal .modalCard {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-top: 100px;
}
.about .modal .modalCard img {
  max-width: 500px;
  max-height: 500px;
  -o-object-position: center;
     object-position: center;
  -o-object-fit: cover;
     object-fit: cover;
}
.about .modal .modalCard .cardInfo {
  padding: 20px;
  padding-left: 50px;
  line-height: 1.5;
}
.about .modal .modalCard .name {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.75rem;
  color: var(--PrimaryColor);
  padding-bottom: 15px;
  text-align: center;
}
.about .modal .modalCard .linkName {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.1rem;
  color: var(--PrimaryColor);
  padding-left: 5px;
  transition: 0.3s;
}
.about .modal .modalCard .linkName:hover {
  color: rgb(255, 230, 167);
  transition: 0.3s;
}
.about .modal .modalCard .instrument, .about .modal .modalCard .skill, .about .modal .modalCard .link, .about .modal .modalCard .modalTextAdd {
  font-family: Garamond, Helvetica, serif;
  font-size: 1rem;
  color: rgb(182, 136, 22);
  padding: 0 20px 0 20px;
}
.about .modal .modalCard .link, .about .modal .modalCard .modalTextAdd {
  padding-top: 15px;
}
.about .modal .close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: rgb(100, 100, 100);
  font-size: 40px;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.5s;
}
.about .modal .prev, .about .modal .next {
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  color: rgb(100, 100, 100);
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
  transition: 0.5s;
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.about .modal .prev {
  left: 0;
}
.about .modal .next {
  right: 0;
  padding-right: 35px;
}
.about .modal .close:hover, .about .modal .prev:hover, .about .modal .next:hover {
  color: antiquewhite;
  transform: scale(1.1);
  transition: 0.5s;
}

@media screen and (max-width: 959px) {
  .about {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .about .modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 2000;
  }
  .about .modal .modalCard {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
  }
  .about .modal .modalCard img {
    -o-object-position: center;
       object-position: center;
    -o-object-fit: cover;
       object-fit: cover;
    margin: auto;
    padding: 1px;
  }
  .about .modal .modalCard .cardInfo {
    padding-left: 50px;
    line-height: 1.5;
  }
  .about .modal .modalCard .name {
    font-family: Garamond, Helvetica, serif;
    font-size: 1.75rem;
    color: var(--PrimaryColor);
    padding-bottom: 15px;
    text-align: center;
  }
  .about .modal .modalCard .linkName {
    font-family: Garamond, Helvetica, serif;
    font-size: 1.1rem;
    color: var(--PrimaryColor);
    padding-left: 5px;
    transition: 0.3s;
  }
  .about .modal .modalCard .instrument, .about .modal .modalCard .skill, .about .modal .modalCard .link, .about .modal .modalCard .modalTextAdd {
    font-family: Garamond, Helvetica, serif;
    font-size: 1rem;
    color: rgb(182, 136, 22);
    padding: 0 20px 0 20px;
  }
  .about .modal .modalCard .link, .about .modal .modalCard .modalTextAdd {
    padding-top: 15px;
  }
}
@media screen and (max-width: 500px) {
  .about .aboutTitle {
    font-size: 1.9rem;
  }
  .about .secContent {
    justify-content: center;
  }
  .about .secContent .aboutCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  .about .secContent .aboutCard img {
    max-width: 350px;
    max-height: 350px;
    padding-bottom: 1rem;
  }
  .about .secContent .aboutCard .cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  .about .secContent .aboutCard .cardInfo .name, .about .secContent .aboutCard .cardInfo .instrument, .about .secContent .aboutCard .cardInfo .skill, .about .secContent .aboutCard .cardInfo .link {
    display: flex;
    justify-items: center;
    font-weight: 600;
    padding: 0.2rem;
  }
}/*# sourceMappingURL=about.css.map */