.tour {
    width: 90%;
    background: var(--backgroundColor);
    margin: 0 auto;
    padding-top: 20vh;

    .tourTitle {
        font-family: Garamond, Helvetica, serif;
        font-size: 2.1rem;
        color: var(--PrimaryColor);
        text-align: center;
        margin: 0 auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .secContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;

        .newsCard {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 75%;
            max-height: auto;
            overflow: hidden; 
            border: 1px solid rgb(24, 20, 12);
            border-radius: 4px;
            background: var(--backgroundColor);
            margin: 0.5rem auto;
            transition: 0.5s;

            &:hover{
                transition: 1s;
                transform: scale(1.025);
                box-shadow: 0 0 2px 2px rgb(19, 19, 19);
                border: none;
            }
            
            .cardInfo {
                padding: 20px;
                line-height: 1.5;

                .cardTitle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .title {
                        font-family: Garamond, Helvetica, serif;
                        font-size: 1.8rem;
                        color: var(--textColor);
                        padding-bottom: 15px;
                        padding-right: 50px;
                        text-align: center;
                    }
        
                    .date {
                        font-family: Garamond, Helvetica, serif;
                        font-size: 1.8rem;
                        color: var(--textColor);
                        padding-bottom: 15px;
                        right: 5rem;
                    }
                }

                .location {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.6rem;
                    color: var(--textColor);
                    transition: 0.3s;
                    text-align: center;
                    padding: 0;
    
                    &:hover {
                        color: rgb(255, 230, 167);
                        transition: 0.3s;
                    }
                }
    
                .text {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1rem;
                    color: var(--textColor);
                    padding: 0 20px 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .tour {
        width: 100%;

        .secContent {
            .newsCard {
                width: 80%;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .tour {
        width: 100%;

        .tourTitle {
            font-size: 1.9rem;
            text-align: center;
            margin: 0 auto;
            padding: 1rem 0 2rem 0;
        }

        .secContent {
            .newsCard {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 95%;
                padding: 1.2rem 0 1.2rem 0;

                .cardInfo {
                    padding: 0;
                
                    .cardTitle {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .title, .date {
                            display: flex;
                            font-size: 1.5rem;
                            padding: 0;
                            justify-items: center;
                        }
                    }

                    .location, .text {
                        font-size: 1.35rem;
                        text-align: center;
                    }
                }
            }
        }
    }
}