.news {
  width: 90%;
  background: var(--backgroundColor);
  margin: 0 auto;
  padding-top: 20vh;
  padding-bottom: 5vh;
}
.news .newsTitle {
  font-family: Garamond, Helvetica, serif;
  font-size: 2.2rem;
  color: var(--PrimaryColor);
  text-align: center;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 40px;
}
.news .secContent {
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.news .secContent .newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-height: auto;
  overflow: hidden;
  border: 1px solid rgb(24, 20, 12);
  border-radius: 4px;
  background: var(--backgroundColor);
  margin: 15px auto;
}
.news .secContent .newsCard:hover {
  transition: 1s;
  box-shadow: 0 0 20px 20px rgb(19, 19, 19);
}
.news .secContent .newsCard img {
  width: 350px;
  height: 350px;
  margin: auto;
}
.news .secContent .newsCard .cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  margin: auto;
}
.news .secContent .newsCard .cardInfo .title {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.9rem;
  color: var(--PrimaryColor);
  padding-bottom: 1rem;
}
.news .secContent .newsCard .cardInfo .linkName {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.6rem;
  color: var(--PrimaryColor);
  transition: 0.3s;
}
.news .secContent .newsCard .cardInfo .linkName:hover {
  color: rgb(255, 230, 167);
  transition: 0.3s;
}
.news .secContent .newsCard .cardInfo .subtitle, .news .secContent .newsCard .cardInfo .text, .news .secContent .newsCard .cardInfo .link {
  font-family: Garamond, Helvetica, serif;
  font-size: 1.4rem;
  color: rgb(182, 136, 22);
  padding: 0 20px 0 20px;
  margin: auto;
}

@media screen and (max-width: 959px) {
  .news {
    width: 100%;
  }
  .news .secContent .newsCard {
    display: flex;
    flex-direction: column;
    max-height: auto;
    width: 100%;
  }
  .news .secContent .newsCard img {
    margin: 1rem auto 2rem auto;
    padding: 0.2rem;
  }
  .news .secContent .newsCard .cardInfo {
    margin: 0 auto 2rem auto;
  }
  .news .secContent .newsCard .cardInfo .title {
    font-size: 1.75rem;
  }
  .news .secContent .newsCard .cardInfo .linkName {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 500px) {
  .news {
    width: 100%;
  }
  .news .secContent .newsCard .cardInfo {
    margin: 0 auto 2rem auto;
  }
  .news .secContent .newsCard .cardInfo .title {
    font-size: 1.35rem;
  }
  .news .secContent .newsCard .cardInfo .linkName {
    font-size: 1.3rem;
  }
}/*# sourceMappingURL=news.css.map */