.about {
    width: 90%;
    background: var(--backgroundColor);
    margin: auto;
    padding-top: 20vh;

    .aboutTitle {
        font-family: Garamond, Helvetica, serif;
        font-size: 2.2rem;
        color: var(--PrimaryColor);
        text-align: center;
        margin: 0 auto;
        padding-top: 25px;
        padding-bottom: 40px;
    }

    .presseText1 {
        font-family: Garamond, Helvetica, serif;
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--textColor);
        text-align: center;
        line-height: 1.75;
        max-width: 80%;
        margin: 0 auto;
        padding-bottom: 45px;
    }

    .presseText2 {
        font-family: Garamond, Helvetica, serif;
        font-size: 1.2rem;
        color: var(--textColor);
        text-align: center;
        line-height: 1.75;
        max-width: 80%;
        margin: 0 auto;
        padding-bottom: 45px;
    }

    .secContent {
        justify-content: center;
        gap: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 50px;

        .aboutCard {
            display: flex;
            flex-direction: column;
            justify-content: left;
            min-width: 350px;
            max-height: auto;
            overflow: hidden; 
            border: 1px solid rgb(24, 20, 12);
            border-radius: 4px;
            background: var(--backgroundColor);
            padding-bottom: 25px;
            margin: 15px auto;
            transition: 0.5s;

            &:hover{
                transition: 1s;
                transform: scale(1.05);
                box-shadow: 0 0 5px 5px var(--SecondaryColor);
                background-color: rgba(0, 0, 0, 0.2);
                border: none;
            }

            img {
                width: 350px;
                height: 350px;
                padding-bottom: 1vh;
            }

            .cardInfo {
                padding: 20px;
                line-height: 1.5;
            }

            .name {
                font-family: Garamond, Helvetica, serif;
                font-size: 1.75rem;
                color: var(--PrimaryColor);
                padding-bottom: 15px;
                text-align: center;
            }

            .linkName {
                font-family: Garamond, Helvetica, serif;
                font-size: 1.1rem;
                color: var(--PrimaryColor);
                padding-left: 5px;
                transition: 0.3s;

                &:hover {
                    color: rgb(255, 230, 167);
                    transition: 0.3s;
                }
            }

            .instrument, .skill, .link {
                font-family: Garamond, Helvetica, serif;
                font-size: 1rem;
                color: var(--SecondaryColor);
                padding: 0 20px 0 20px;
            }
        }
    }

    .modal {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 2000;

        .modalCard {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            padding-top: 100px;

            img {
                max-width: 500px;
                max-height: 500px;
                object-position: center;
                object-fit: cover;
            }

            .cardInfo {
                padding: 20px;
                padding-left: 50px;
                line-height: 1.5;
            }

            .name {
                font-family: Garamond, Helvetica, serif;
                font-size: 1.75rem;
                color: var(--PrimaryColor);
                padding-bottom: 15px;
                text-align: center;
            }

            .linkName {
                font-family: Garamond, Helvetica, serif;
                font-size: 1.1rem;
                color: var(--PrimaryColor);
                padding-left: 5px;
                transition: 0.3s;

                &:hover {
                    color: rgb(255, 230, 167);
                    transition: 0.3s;
                }
            }

            .instrument, .skill, .link, .modalTextAdd {
                font-family: Garamond, Helvetica, serif;
                font-size: 1rem;
                color: rgb(182, 136, 22);
                padding: 0 20px 0 20px;
            }

            .link, .modalTextAdd {
                padding-top: 15px;
            }
        }
        
        .close {
            position: absolute;
            top: 15px;
            right: 35px;
            color: rgb(100, 100, 100);
            font-size: 40px;
            font-weight: bolder;
            cursor: pointer;
            transition: 0.5s;
        }
        
        .prev, .next {
            position: absolute;
            top: 50%;
            width: auto;
            padding: 16px;
            color: rgb(100, 100, 100);
            font-weight: bold;
            font-size: 30px;
            cursor: pointer;
            transition: 0.5s;
            -webkit-user-select: none;
            user-select: none;
        }
        
        .prev {
            left: 0;
        }
        
        .next {
            right: 0;
            padding-right: 35px;
        }
        
        .close:hover, .prev:hover, .next:hover {
            color: antiquewhite;
            transform: scale(1.1);
            transition: 0.5s;
        }
    }
}

@media screen and (max-width: 959px) {
    .about {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .about {
        .modal {
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.9);
            z-index: 2000;
    
            .modalCard {
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 100%;
                margin: 0 auto;
                padding-top: 40px;
    
                img {
                    object-position: center;
                    object-fit: cover;
                    margin: auto;
                    padding: 1px;
                }
    
                .cardInfo {
                    padding-left: 50px;
                    line-height: 1.5;
                }
    
                .name {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.75rem;
                    color: var(--PrimaryColor);
                    padding-bottom: 15px;
                    text-align: center;
                }
    
                .linkName {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.1rem;
                    color: var(--PrimaryColor);
                    padding-left: 5px;
                    transition: 0.3s;
                }
    
                .instrument, .skill, .link, .modalTextAdd {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1rem;
                    color: rgb(182, 136, 22);
                    padding: 0 20px 0 20px;
                }
    
                .link, .modalTextAdd {
                    padding-top: 15px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .about {
        .aboutTitle {
            font-size: 1.9rem;
        }

        .secContent {
            justify-content: center;

            .aboutCard {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90%;
                margin: 0 auto;

                img {
                    max-width: 350px;
                    max-height: 350px;
                    padding-bottom: 1rem;
                }

                .cardInfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0;

                    .name, .instrument, .skill, .link {
                        display: flex;
                        justify-items: center;
                        font-weight: 600;
                        padding: 0.2rem;
                    }
                }
            }
        }
    }
}