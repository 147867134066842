:root {
  --PrimaryColor: goldenrod;
  --PrimaryColorBG: rgba(218, 165, 32, 0.5);
  --SecondaryColor: rgb(152, 113, 14);
  --textColor: antiquewhite;
  --textColorNegative: rgb(64, 59, 53);
  --iconColor: antiquewhite;
  --gradientColor: linear-gradient(to right, yellow, rgb(255, 195, 42));
  --backgroundColor1: rgba(0, 0, 0, 0.75);
  --backgroundColor2: rgba(0, 0, 0, 0.4);
  --whiteColor: white;
  --blackColor: black;
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--backgroundColor1);
  padding: 2rem;
}
.footer .icon {
  color: var(--SecondaryColor);
  margin: 0.5rem;
  transition: 0.75s ease-in-out;
}
.footer .instagram {
  font-size: 40px;
}
.footer .instagram:hover {
  color: rgb(173, 171, 171);
  transition: 0.4s ease-in-out;
}
.footer .facebook {
  font-size: 32px;
}
.footer .facebook:hover {
  color: rgb(68, 83, 255);
  transition: 0.4s ease-in-out;
}
.footer .youtube {
  font-size: 36px;
}
.footer .youtube:hover {
  color: rgb(243, 26, 26);
  transition: 0.4s ease-in-out;
}
.footer .spotify {
  font-size: 32px;
}
.footer .spotify:hover {
  color: rgb(78, 240, 78);
  transition: 0.4s ease-in-out;
}
.footer .email {
  font-size: 34px;
}
.footer .email:hover {
  color: rgb(235, 47, 235);
  transition: 0.4s ease-in-out;
}
.footer .onlyfans {
  font-size: 36px;
}
.footer .onlyfans:hover {
  color: rgb(217, 166, 36);
  transition: 0.4s ease-in-out;
}
.footer .copyright {
  color: var(--textColor);
}
.footer .copyright .copyrightText {
  padding-left: 0.3rem;
  font-style: "Garamond", "Helvetica", serif;
  font-weight: 500;
  color: var(--textColor);
  padding-left: 15px;
}/*# sourceMappingURL=footer.css.map */