:root {
    --PrimaryColor: goldenrod;
    --SecondaryColor: rgb(152, 113, 14);
    --textColor: antiquewhite;
    --iconColor: antiquewhite;
    --gradientColor: linear-gradient(to right, yellow, rgb(255, 195, 42));
    --backgroundColor: rgba(0, 0, 0, 0.4);
    --whiteColor: white;
    --blackColor: black;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: radial-gradient(circle, rgba(255, 194, 73, 0.284) 0%, rgba(16 ,16 ,16 , 0.75) 75%);
    z-index: -999;
    
    img {
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        z-index: -1000;
    }
}