.news {
    width: 90%;
    background: var(--backgroundColor);
    margin: 0 auto;
    padding-top: 20vh;
    padding-bottom: 5vh;

    .newsTitle {
        font-family: Garamond, Helvetica, serif;
        font-size: 2.2rem;
        color: var(--PrimaryColor);
        text-align: center;
        margin: 0 auto;
        padding-top: 25px;
        padding-bottom: 40px;
    }

    .secContent {
        justify-content: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .newsCard {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            max-height: auto;
            overflow: hidden; 
            border: 1px solid rgb(24, 20, 12);
            border-radius: 4px;
            background: var(--backgroundColor);
            margin: 15px auto;

            &:hover{
                transition: 1s;
                box-shadow: 0 0 15px 15px rgb(19, 19, 19);
            }

            img {
                width: 350px;
                height: 350px;
                margin: auto;
            }

            .cardInfo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                line-height: 1.5;
                margin: auto;

                .title {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.9rem;
                    color: var(--PrimaryColor);
                    padding-bottom: 1rem;
                }
    
                .linkName {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.6rem;
                    color: var(--PrimaryColor);
                    transition: 0.3s;
    
                    &:hover {
                        color: var(--textColor);
                        transition: 0.3s;
                    }
                }
    
                .subtitle, .text, .link {
                    font-family: Garamond, Helvetica, serif;
                    font-size: 1.4rem;
                    color: rgb(182, 136, 22);
                    padding: 0 20px 0 20px;
                    margin: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    .news {
        width: 100%;

        .secContent {
            .newsCard {
                display: flex;
                flex-direction: column;
                max-height: auto;
                width: 100%;
                
                img {
                    margin: 1rem auto 2rem auto;
                    padding: 0.2rem;
                }

                .cardInfo {
                    margin: 0 auto 2rem auto;

                    .title {
                        font-size: 1.75rem;
                    }

                    .linkName {
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .news {
        width: 100%;

        .secContent {
            .newsCard {
                .cardInfo {
                    margin: 0 auto 2rem auto;

                    .title {
                        font-size: 1.35rem;
                    }

                    .linkName {
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}