@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Cutive+Mono&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=GFS+Didot&family=Italianno&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Sawarabi+Gothic&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'GFS Didot';
}

:root {
    --PrimaryColor: rgb(203, 161, 55);
    --PrimaryColorBG: rgba(218, 165, 32, 0.5);
    --SecondaryColor: rgb(152, 113, 14);
    --textColor: rgb(218, 195, 142);
    --textColorNegative: rgb(64, 59, 53);
    --iconColor: antiquewhite;
    --gradientColor: linear-gradient(to right, yellow, rgb(255, 195, 42));
    --backgroundColor: rgba(0, 0, 0, 0.4);
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.section {
    padding: 4rem 0 2rem;
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
}

.grid {
    display: grid;
    align-items: center;
}

.btn {
    padding: 0.6rem 1.5rem;
    font-style: 'Italianno', 'Helvetica', serif;
    color: var(--textColor);
    background: linear-gradient(to right, var(--SecondaryColor), var(--PrimaryColor));
    border-radius: 3rem;
    border: none;
    outline: none;
    cursor: pointer;
}

body {
    background-color: rgb(19, 17, 13);
}