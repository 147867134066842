:root {
  --PrimaryColor: goldenrod;
  --SecondaryColor: rgb(152, 113, 14);
  --textColor: antiquewhite;
  --iconColor: antiquewhite;
  --gradientColor: linear-gradient(to right, yellow, rgb(255, 195, 42));
  --backgroundColor: rgba(0, 0, 0, 0.2);
  --whiteColor: white;
  --blackColor: black;
}

.video {
  width: 90%;
  background: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 7rem;
}
.video h2 {
  font-family: Garamond, Helvetica, serif;
  font-size: 2rem;
  color: var(--PrimaryColor);
  padding-bottom: 4rem;
  padding-top: 3rem;
  margin: 0 auto;
}
.video .ytVideo {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video .ytVideo iframe {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
}

@media screen and (max-width: 959px) {
  .video {
    width: 100%;
    padding-top: 6rem;
  }
  .video h2 {
    font-family: Garamond, Helvetica, serif;
    font-size: 1.75rem;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
}
@media screen and (max-width: 640px) {
  .video {
    width: 100%;
    padding-top: 6rem;
  }
  .video h2 {
    font-family: Garamond, Helvetica, serif;
    font-size: 1.7rem;
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
  .video .ytVideo {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .video .ytVideo iframe {
    position: absolute;
    top: 2.5%;
    left: 2.5%;
    width: 95%;
    height: 95%;
  }
}/*# sourceMappingURL=media.css.map */