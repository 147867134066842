.video {
    width: 90%;
    background: var(--backgroundColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 7rem;

    h2 {
        font-family: Garamond, Helvetica, serif;
        font-size: 2rem;
        color: var(--PrimaryColor);
        padding-bottom: 4rem;
        padding-top: 3rem;
        margin: 0 auto;
    }

    .ytVideo {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    }

    .ytVideo iframe {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
    }
}

@media screen and (max-width: 959px) {
    .video {
        width: 100%;
        padding-top: 6rem;
    
        h2 {
            font-family: Garamond, Helvetica, serif;
            font-size: 1.75rem;
            padding-bottom: 2rem;
            padding-top: 1.5rem;    
        }
    }
}

@media screen and (max-width: 640px) {
    .video {
        width: 100%;
        padding-top: 6rem;
    
        h2 {
            font-family: Garamond, Helvetica, serif;
            font-size: 1.7rem;
            padding-bottom: 2rem;
            padding-top: 1.5rem;    
        }

        .ytVideo {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
        }
    
        .ytVideo iframe {
            position: absolute;
            top: 2.5%;
            left: 2.5%;
            width: 95%;
            height: 95%;
        }
    }
}
