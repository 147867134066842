:root {
    --PrimaryColorBG: rgba(218, 165, 32, 0.5);
    --textColorNegative: rgb(64, 59, 53);
    --backgroundColor1: rgba(0, 0, 0, 0.75);
    --backgroundColor2: rgba(0, 0, 0, 0.4);
}

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--backgroundColor1);
    padding: 2rem;

    .icon {
        color: var(--SecondaryColor);
        margin: 0.5rem;
        transition: 0.75s ease-in-out;
    }
    .instagram {
        font-size: 40px;
        &:hover {
            color: rgb(173, 171, 171);
            transition: 0.4s ease-in-out;
        }
    }
    .facebook {
        font-size: 32px;
        &:hover {
            color: rgb(68, 83, 255);
            transition: 0.4s ease-in-out;
        }
    }
    .youtube {
        font-size: 36px;
        &:hover {
            color: rgb(243, 26, 26);
            transition: 0.4s ease-in-out;
        }
    }
    .spotify {
        font-size: 32px;
        &:hover {
            color: rgb(78, 240, 78);
            transition: 0.4s ease-in-out;
        }
    }
    .email {
        font-size: 34px;
        &:hover {
            color: rgb(235, 47, 235);
            transition: 0.4s ease-in-out;
        }
    }
    .onlyfans {
        font-size: 36px;
        &:hover {
            color: rgb(217, 166, 36);
            transition: 0.4s ease-in-out;
        }
    }

    .copyright {
        color: var(--textColor);
        
        .copyrightText {
            padding-left: 0.3rem;
            font-style: 'Garamond', 'Helvetica', serif;
            font-weight: 500;
            color: var(--textColor);
            padding-left: 15px;
        }
        
    }
}